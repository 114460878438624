export const abstract_data = [
     // {
     //     id: "A022",
     //     title: "Patate",
     //     image: `${process.env.PUBLIC_URL + "/img/abstract/papate.webp"}`,
     //     technique: "Encre à alcool/toile",
     //     dimension: "76 x 51",
     //     date:"2021",
     // },
     {
        id: "A027",
        title: "Brainstorm",
        image: `${process.env.PUBLIC_URL + "/img/abstract/brainstorm.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "51 x 61",
        date:"2025",
        statut:"Disponible",

        statut:"Disponible",
    },
    {
        id: "A026",
        title: "AR3664 X3.98",
        image: `${process.env.PUBLIC_URL + "/img/abstract/solar_storm.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "51 x 61",
        date:"2024",
        statut:"Disponible",

    },
    {
        id: "A025",
        title: "L'écho des galaxies",
        image: `${process.env.PUBLIC_URL + "/img/abstract/echoes_galaxy.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2024",
        statut:"Disponible",

    },
    {
        id: "A024",
        title: "Mediterranea",
        image: `${process.env.PUBLIC_URL + "/img/abstract/mediterranea.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2024",
        statut:"Vendu",

    },
     {
        id: "A023",
        title: "Coucher de soleil quantique",
        image: `${process.env.PUBLIC_URL + "/img/abstract/coucher_soleil_quantique.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2023",
        statut:"Disponible",

    },
     {
        id: "A022",
        title: "Code barre humain",
        image: `${process.env.PUBLIC_URL + "/img/abstract/code_barre_humain.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "76 x 101",
        date:"2023",
        statut:"Disponible",

    },
    {
        id: "A021",
        title: "Soleil quantique",
        image: `${process.env.PUBLIC_URL + "/img/abstract/soleil_quantique.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2023",
        statut:"Disponible",

    },
    {
        id: "A020",
        title: "Transition II",
        image: `${process.env.PUBLIC_URL + "/img/abstract/transition2.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2023",
        statut:"Disponible",

    },
    {
        id: "A019",
        title: "Transition I",
        image: `${process.env.PUBLIC_URL + "/img/abstract/transition1.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2023",
        statut:"Vendu",

    },
    {
        id: "A018",
        title: "Pastis par temps bleu, Pastis délicieux",
        image: `${process.env.PUBLIC_URL + "/img/abstract/pastis.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2022",
        statut:"Disponible",

    },
    {
        id: "A017",
        title: "Union",
        image: `${process.env.PUBLIC_URL + "/img/abstract/union.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2022",
        statut:"Disponible",

    },
    {
        id: "A016",
        title: "Zig Zag",
        image: `${process.env.PUBLIC_URL + "/img/abstract/zig_zag.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2022",
        statut:"Disponible",

    },
    {
        id: "A015",
        title: "Perspective",
        image: `${process.env.PUBLIC_URL + "/img/abstract/perspective.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2022",
        statut:"Disponible",

    },
    {
        id: "A014",
        title: "Dual",
        image: `${process.env.PUBLIC_URL + "/img/abstract/dual.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2021",
        statut:"Vendu",

    },
    {
        id: "A013",
        title: "Dégradé dimentsionnel",
        image: `${process.env.PUBLIC_URL + "/img/abstract/degrade_dimensionnel.webp"}`,
        technique: "Encre à alcool/papier Yupo",
        dimension: "66 x 51",
        date:"2021",
        statut:"Disponible",

    },
    {
        id: "A012",
        title: "Fracture",
        image: `${process.env.PUBLIC_URL + "/img/abstract/fracture.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2021",
        statut:"Disponible",

    },
    // {
    //     id: "A011",
    //     title: "Entropie jaune",
    //     image: `${process.env.PUBLIC_URL + "/img/abstract/entropie_jaune.webp"}`,
    //     technique: "Encre à alcool/toile",
    //     dimension: "61 x 45.5",
    //     date:"2022",
    // },
    // {
    //     id: "A010",
    //     title: "Entropie cyan",
    //     image: `${process.env.PUBLIC_URL + "/img/abstract/entropie_cyan.webp"}`,
    //     technique: "Encre à alcool/toile",
    //     dimension: "61 x 45.5",
    //     date:"2022",
    // },
    // {
    //     id: "A009",
    //     title: "Entropie magenta",
    //     image: `${process.env.PUBLIC_URL + "/img/abstract/entropie_magenta.webp"}`,
    //     technique: "Encre à alcool/toile",
    //     dimension: "61 x 45.5",
    //     date:"2022",
    // },
    // {
    //     id: "A008",
    //     title: "Entropie noire",
    //     image: `${process.env.PUBLIC_URL + "/img/abstract/entropie_noire.webp"}`,
    //     technique: "Encre à alcool/toile",
    //     dimension: "61 x 45.5",
    //     date:"2021",
    // },
    {
        id: "A007",
        title: "Onde noire",
        image: `${process.env.PUBLIC_URL + "/img/abstract/onde_noire.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 45.5",
        date:"2021",
        statut:"Vendu",

    },
    {
        id: "A006",
        title: "Distorsion BJ",
        image: `${process.env.PUBLIC_URL + "/img/abstract/distorsion_bj.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2021",
        statut:"Disponible",

    },
    {
        id: "A005",
        title: "Distorsion RB",
        image: `${process.env.PUBLIC_URL + "/img/abstract/distorsion_rb.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2021",
        statut:"Disponible",

    },
    {
        id: "A004",
        title: "Distorsion RJ",
        image: `${process.env.PUBLIC_URL + "/img/abstract/distorsion_rj.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "61 x 61",
        date:"2021",
        statut:"Disponible",

    },
    {
        id: "A003",
        title: "Glacier",
        image: `${process.env.PUBLIC_URL + "/img/abstract/glacier.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "51 x 40.5",
        date:"2021",
        statut:"Disponible",

    },
    {
        id: "A002",
        title: "Jungle",
        image: `${process.env.PUBLIC_URL + "/img/abstract/jungle.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "51 x 40.5",
        date:"2021",
        statut:"Disponible",

    },
    {
        id: "A001",
        title: "Océan",
        image: `${process.env.PUBLIC_URL + "/img/abstract/ocean.webp"}`,
        technique: "Encre à alcool/toile",
        dimension: "76 x 51",
        date:"2021",
        statut:"Disponible",

    },
  ];